import React from "react";
import { Container, Row, Col } from "reactstrap";



import floor from '../../assests/images/floor.jpg'
import fab from '../../assests/images/fab.jpg'
import rapid from '../../assests/images/rapid.jpg'
import cutting from '../../assests/images/cutting.jpg'
import client from '../../assests/images/client.jpg'
import med3 from '../../assests/images/med3.png'
import warehouses from '../../assests/images/warehouses.jpg'
import s1_1 from '../../assests/images/s1.1.jpg'
import s1_2 from '../../assests/images/s1.2.jpg'
import s1_3 from '../../assests/images/s1.3.jpg'
import s1_4 from '../../assests/images/s1.4.jpg'
import s1_5 from '../../assests/images/s1.5.jpg'
import s1_6 from '../../assests/images/s1.6.png'





const coursesData = [
  {
    id: "01",
    title: "Warehouses",
    lesson: "Mechtech Infra revolutionizes warehousing with innovative design and sustainable solutions, crafting spaces that seamlessly merge efficiency and quality for the future of storage.",
    students: 12.5,
    rating: 5.9,
    imgUrl: warehouses,
  },

  {
    id: "02",
    title: "Mezzanine Floor",
    lesson: "Discover elevated space solutions with Mechtech Infra's mezzanine floors, seamlessly blending innovation and practicality for efficient spatial optimization.",
    students: 12.5,
    rating: 5.9,
    imgUrl: floor,
  },

  {
    id: "03",
    title: "Pre - Fab Structures",
    lesson: "Explore elevated space solutions with Mechtech Infra's pre-fab structures, seamlessly blending innovation and practicality for efficient spatial optimization.",
    students: 12.5,
    rating: 5.9,
    imgUrl: fab,
  },
];

const coursesData1 = [

  {
    id: "02",
    title: "Cutting-Edge Technology Integration",
    lesson: "Utilizing state-of-the-art technology for precision and efficiency in construction processes.",
    students: 12.5,
    rating: 5.9,
    imgUrl:  cutting,
  },

  {
    id: "01",
    title: "Internal Partitions / Rapid Construction",
    lesson: "Rapidly redefine interiors with Mechtech Infra's swift construction of internal partitions, seamlessly blending speed and quality for efficient and stylish transformations.",
    students: 12.5,
    rating: 5.9,
    imgUrl: rapid,
  },

  {
    id: "03",
    title: "Client-Centric Project Customization",
    lesson: "Tailoring solutions to client needs, ensuring transparent communication and surpassing expectations in every project.",
    students: 12.5,
    rating: 5.9,
    imgUrl: client,
  },
  
];

const Courses = () => {
  return (
    <section id="courses">
      <Container>
      
        <h1 className="tw-text-center tw-font-bold tw-border-b-2 tw-border-black tw-mb-16">Empowering Construction with Cutting-Edge Plant <br/>& Equipments Offering by Mechtech Infra</h1>
            <div className="lg:tw-flex tw-flex-row-reverse tw-gap-[10%] tw-items-center">
              <div class="wrapper2">
                    
                    <div class="container2">
                    <div class="horizontal"><img src={s1_4} alt="" /></div>
                    <div class="vertical"><img  src={s1_2} alt="" /></div>
                    <div><img src={s1_3} alt="" /></div>
                    <div class="big"><img src={s1_1} alt="" /></div>
                    <div class="vertical1"><img className="img" src={s1_5} alt="" /></div>
                    <div><img src={s1_6} alt="" /></div>
                </div>
            </div>
            <div className="text">
              <p className="tw-max-w-6xl tw-text-justify tw-text-black">Mechtech Infra stands at the forefront of transforming the construction landscape, setting new standards of efficiency and quality with our comprehensive array of advanced plant services and equipment available on a rental basis. With an unwavering commitment to excellence ingrained in our ethos, our plant services serve as steadfast pillars of efficiency, offering seamless solutions meticulously tailored to meet the diverse needs of every construction endeavor. From the inception of cutting-edge plant constructions to the provision of personalized client services, Mechtech Infra epitomizes innovation, reliability, and unparalleled dedication in the industry.</p>
              <div className="tw-flex tw-gap-5">
                <div className="items">
                  <ul className="tw-list-disc tw-font-bold tw-leading-10">
                    <li>Stone Crusher Plants</li>
                    <li>Batching Plants</li>
                    <li>Sand Washing Plants</li>
                    <li>Wet Mix Macadam Plants</li>
                  </ul>
                </div>
                <div className="items">
                <ul className="tw-list-disc tw-font-bold tw-leading-10">
                    <li>Excavators</li>
                    <li>Motor graders</li>
                    <li>Backhoe-loaders</li>
                    <li>and Many More....</li>
                  </ul>
                </div>
              </div>
            </div>
            </div>



      </Container>
    </section>
  );
};

export default Courses;
