import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Advanced Tools and Equipment",
    desc: "Access to state-of-the-art tools and equipment to enhance efficiency and precision in construction processes.",
    icon: "fa-solid fa-screwdriver-wrench",
  },
  {
    title: "State-of-the-Art Workspaces",
    desc: "Equipped with cutting-edge technology for efficient project management and collaboration.",
    icon: "fa-solid fa-palette",
  },
  

  {
    title: "Safety Standards",
    desc: " Strict adherence to safety protocols and measures to ensure a secure working environment.",
    icon: "fa-solid fa-shield-heart",
  },

  
  
  {
    title: "Environmental Sustainability",
    desc: "Implementation of eco-friendly practices in construction, promoting a green and sustainable workplace.",
    icon: "fa-solid fa-seedling",
  },
];

const Features = () => {
  return (
    <section>
      <Container>
      <div className="tw-text-center tw-mb-16">
      <h1 className="tw-font-bold ">Facilities at a Glance</h1>
    </div>
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="3" md="6" key={index}>
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <i class={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;

