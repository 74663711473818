import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import "./footer.css";
import logo from '../../assests/Logos/logo-main.png'

const footerQuickLinks = [
  {
    display: "Home",
    url: "#",
  },
  {
    display: "Services",
    url: "/servicemain",
  },

  {
    display: "Projects",
    url: "#projects",
  },

  // {
  //   display: "Facilities",
  //   url: "#facilities",
  // },
  {
    display: "Gallery",
    url: "#gallery",
  },
  {
    display: "Contact Us",
    url: "/Contact",
  },
];



const Footer = () => {
  return (
    <footer className="footer tw-bg-[#f3b100]" id="footer">
      <Container>
        <div className="tw-flex lg:tw-flex-row 3xs:tw-flex-col">
        <Col lg="3" md="6" className="mb-4">
          <h2 className=" d-flex align-items-center gap-1 tw-w-40">
              <img src={logo} alt="" />
            </h2>

            <div className="follows">
              <p className="mb-0 tw-text-white">Follow us on social media</p>
              <span>
                {" "}
                <a href="facebook.com" className="tw-text-xl tw-font-bold">
                  <i class="ri-facebook-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com" className="tw-text-xl tw-font-bold">
                  <i class="ri-instagram-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com" className="tw-text-xl tw-font-bold">
                  <i class="ri-linkedin-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com" className="tw-text-xl tw-font-bold">
                  <i class="ri-twitter-line tw-text-white"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="text fw-bold tw-underline tw-text-black tw-font-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item tw-text-white">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          

          <Col lg="3" md="6">
            <h6 className="fw-bold tw-underline">Address</h6>

            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Flat no 501, DTR Enclave, </p>
            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Sita nagar,Madhurawada, </p>
            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Visakhapatnam -530048.</p>
          </Col>
          <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.3847007778427!2d83.35053401268361!3d17.820592077188422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b7341b1a4e7%3A0xe9442a844769b468!2sDTR%20Residency!5e0!3m2!1sen!2sin!4v1708922048953!5m2!1sen!2sin" width="250" height="200" style={{border:"0", borderEndEndRadius:"20px", borderTopLeftRadius:"20px",borderTopRightRadius:"20px",borderBottomLeftRadius:"20px",alignItems:"center",textAlign:"center",}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
          
        </div>
      </Container>
      <div className="tw-text-center tw-text-black tw-py-2 tw-mb-[-60px]">Made with ❤️ by <a className="tw-text-white tw-font-bold tw-no-underline hover:tw-text-black tw-transition tw-ease-in-out" href="https://www.konkorde.org" target="/">KONKORDE</a></div>
    </footer>
  );
};

export default Footer;
