import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./services.css";
import s1 from '../../assests/images/s1.jpg'
import s2 from '../../assests/images/s2.png'
import s3 from '../../assests/images/s3.png'
import s4 from '../../assests/images/s4.jpg'
import s5 from '../../assests/images/s5.jpg'
import s6 from '../../assests/images/s6.jpg'



const Courses = () => {
  return (
    <section id="services">
      <Container>
      
        <h1 className="tw-text-center tw-font-bold tw-border-b-2 tw-border-black tw-mb-16">Transforming Visions into Reality with Mechtech Infra's Prefab Structures & Integrated Services</h1>
            <div className="lg:tw-flex tw-gap-[10%] tw-items-center">
              <div class="wrapper2">
                    
                    <div class="container2">
                    <div class="horizontal"><img src={s4} alt="" /></div>
                    <div class="vertical"><img  src={s2} alt="" /></div>
                    <div><img src={s5} alt="" /></div>
                    <div class="big"><img   src={s1} alt="" /></div>
                    <div class="vertical1"><img className="img"  src={s3} alt="" /></div>
                    <div><img src={s6} alt="" /></div>
                </div>
            </div>
            <div>
              <p className="tw-max-w-6xl tw-text-justify tw-text-black">Mechtech Infra leads the charge in contemporary construction, specializing in Prefab MS structures highlighted by 50MM & 75MM Sandwich Cement Puf Panels & PVC Panels, offering a seamless fusion of innovation and sustainability. Our solutions are swift, efficient, and stylish, tailored to your specific vision. From meticulous civil works ensuring durable foundations to electrical installations that prioritize safety and efficiency while integrating cutting-edge technology, Mechtech Infra guarantees every detail is meticulously crafted for an exceptional outcome.</p>
             
              <div className="tw-flex tw-gap-5">
                <div>
                  <ul className="tw-list-disc tw-font-bold tw-leading-10">
                    <li>Civil works</li>
                    <li>Elecrical works</li>
                    <li>Plumbing</li>
                    <li>Sanitary complete Works</li>
                  </ul>
                </div>
                <div>
                <ul className="tw-list-disc tw-font-bold tw-leading-10">
                    <li>False celling</li>
                    <li>Flooring</li>
                    <li>Doors</li>
                    <li>Windows</li>
                  </ul>
                </div>
              </div>
            </div>
            </div>



      </Container>
    </section>
  );
};

export default Courses;
