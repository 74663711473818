import React from "react";
import "./testimonial.css";
import {Container, Row, Col} from "reactstrap";
import Slider from "react-slick";

import img from "../../assests/images/testimonial01.png";
import Chairman from '../../assests/Chairman/Chairman.jpg'
import gallery from '../../assests/gallery/gallery.jpg'

const Testimonials = () => {
    const settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1
    };
    return (
        <section>
            <Container>
                <Row>

                    <div
                        className="testimonial__wrapper d-flex  justify-content-between align-items-center ">

                        <div className="testimonial__content w-100 ">
                            <h3 className="tw-mb-10 tw-text-center fw-bold tw-text-5xl">Message From Manager</h3>

                            <Slider {...settings}>
                                <div className=" d-flex lg:tw-flex-row tw-flex-col tw-m-auto tw-justify-center tw-items-center">
                                    
                                    <div className="w-full   lg:tw-p-10 3xs:tw-p-5 md:tw-p-0">
                                        <div className="single__testimonial">
                                            
                                            <p className="tw-text-justify tw-text-black">
                                            As the manager of Mechtech Infra, I am proud to lead a team dedicated to revolutionizing the construction industry through our innovative and environmentally conscious approach. At Mechtech, we prioritize sustainability, employing cutting-edge techniques to ensure that our projects not only meet but exceed environmental standards. Our commitment to excellence is unwavering, as evidenced by our adherence to rigorous quality standards and our dedication to delivering robust and safe solutions to our clients.
                                            </p>

                                            <div className="student__info mt-4">
                                                
                                                <p className="tw-text-justify tw-text-black">Furthermore, transparency and communication are fundamental values at Mechtech, and we strive to foster strong partnerships with our clients by consistently providing clear and open dialogue throughout every stage of the project. With a focus on innovation, quality, and sustainability, Mechtech Infra is poised to continue leading the way in the construction industry, delivering solutions that not only meet the needs of our clients but also contribute to a more sustainable future for generations to come.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>

                </Row>
            </Container>
        </section>
    );
};

export default Testimonials;
