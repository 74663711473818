import React, {useRef, useState} from 'react';
import './contactenq.css';
import enq from '../../assests/images/enq.png'
import Bg3 from '../../assests/images/bg3.png'

function ContactForm() {
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwq_EP0_5Z0YYjWOtFbwozOHXZ3HPsZPdVmBF4Ji' +
            'v-Fu8ijMbMTMT5XHyNOM47q5Mqv/exec';
    const formRef = useRef(null);
    const [isLoading,
        setIsLoading] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const response = await fetch(scriptURL, {
                method: 'POST',
                body: new FormData(formRef.current)
            });

            if (response.ok) {
                alert('Thank you! Our Admission Manager will Contact You Soon.');
                window
                    .location
                    .reload();
            } else {
                throw new Error('Error in form submission.');
            }
        } catch (error) {
            console.error('Error!', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading
                ? (
                    <div className='tw-flex tw-justify-center'>
                        <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
                            <circle
                                class="pl__ring pl__ring--a"
                                cx="120"
                                cy="120"
                                r="105"
                                fill="none"
                                stroke="#000"
                                stroke-width="20"
                                stroke-dasharray="0 660"
                                stroke-dashoffset="-330"
                                stroke-linecap="round"></circle>
                            <circle
                                class="pl__ring pl__ring--b"
                                cx="120"
                                cy="120"
                                r="35"
                                fill="none"
                                stroke="#000"
                                stroke-width="20"
                                stroke-dasharray="0 220"
                                stroke-dashoffset="-110"
                                stroke-linecap="round"></circle>
                            <circle
                                class="pl__ring pl__ring--c"
                                cx="85"
                                cy="120"
                                r="70"
                                fill="none"
                                stroke="#000"
                                stroke-width="20"
                                stroke-dasharray="0 440"
                                stroke-linecap="round"></circle>
                            <circle
                                class="pl__ring pl__ring--d"
                                cx="155"
                                cy="120"
                                r="70"
                                fill="none"
                                stroke="#000"
                                stroke-width="20"
                                stroke-dasharray="0 440"
                                stroke-linecap="round"></circle>
                        </svg>
                    </div>
                )
                : (
                    <div
                        className='tw-bg-gradient-to-b tw-from-[#ff930f] tw-to-[#fff95b] md:tw-m-24 3xs:tw-m-5 tw-rounded-2xl tw-p-16'>
                        <form ref={formRef} onSubmit={handleSubmit} name="contact-form">
                            <h4 className='lg:tw-text-5xl 3xs:tw-text-4xl tw-text-white tw-font-bold'>Enquiry Form</h4>
                            <div
                                className='tw-flex tw-justify-around tw-items-center lg:tw-flex-row 3xs:tw-flex-col'>
                                <div>
                                    <img src={enq} alt=""/>
                                </div>
                                <div className='lg:tw-p-10 3xs:tw-p-0 tw-flex tw-flex-col'>
                                    <h2 className='tw-text-center tw-text-white tw-pb-5'>Feel Free To Fill the Form.</h2>
                                    <input
                                        className='tw-mb-5 lg:tw-w-[35vw] lg:tw-h-[8vh] 3xs:tw-w-full 3xs:tw-h-[8vh] tw-rounded-2xl tw-text-center tw-bg-transparent tw-border-solid tw-border-2 tw-border-white tw-placeholder-white'
                                        type="text"
                                        name="name"
                                        placeholder="Name"/>
                                    <input
                                        className='tw-mb-5 lg:tw-w-[35vw] lg:tw-h-[8vh] 3xs:tw-w-full 3xs:tw-h-[8vh] tw-rounded-2xl tw-text-center tw-bg-transparent tw-border-solid tw-border-2 tw-border-white tw-placeholder-white'
                                        type="text"
                                        name="number"
                                        placeholder="Number"/>
                                    <input
                                        className='tw-mb-5 lg:tw-w-[35vw] lg:tw-h-[8vh] 3xs:tw-w-full 3xs:tw-h-[8vh] tw-rounded-2xl tw-text-center tw-bg-transparent tw-border-solid tw-border-2 tw-border-white tw-placeholder-white'
                                        type="text"
                                        name="school"
                                        placeholder="Your School Name"/>
                                    <button type="submit" id="submit">
                                        <p className='btn3 tw-font-bold'>Submit</p>
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                )}
        </div>
    );
}

export default ContactForm;
