import React from "react";

const FreeCourseCard = (props) => {
  const { imgUrl, title, students, Text } = props.item;

  return (
    <div className="single__free__course">
      <div className="free__course__img mb-5">
        <img src={imgUrl} alt="" className="w-100" />
        
      </div>

      <div className="free__course__details">
        <h6>{title}</h6>

        <div className=" d-flex align-items-center gap-5">
          <span className=" d-flex align-items-center gap-2">
             {students}
          </span>
        
          
        </div>
        <p className="tw-float-right tw-pt-5 tw-text-black tw-font-bold tw-underline">{Text}</p>
      </div>
      
    </div>
    
  );
};

export default FreeCourseCard;
