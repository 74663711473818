import React, { useRef } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import "../components/Header/header.css";
import logo from '../assests/Logos/logo.png';
import {Link} from "react-router-dom";
import Bg1 from '../assests/images/bg1.png';
import Bg2 from '../assests/images/bg2.jpg';
import Contactenq from '../components/Contact-enquiry-form/contactenq';
import Footer from "../components/Footer/Footer";
import Service3 from "../components/Services/Services-3";


const navLinks = [
    {
        display: "Home",
        url: "https://www.mechtechinfra.com/",
      },
      {
        display: "About",
        url: "/about",
      },
    
      {
        display: "Services",
        url: "/servicemain",
      },
      {
        display: "Projects",
        url: "https://www.mechtechinfra.com/#projects",
      },
      // {
      //   display: "Facilities",
      //   url: "https://www.mechtechinfra.com/#facilities",
      // },
      {
        display: "Gallery",
        url: "https://www.mechtechinfra.com/#facilities",
      },
      {
        display: "Contact Us",
        url: "/contact",
      },
  
];
const footerQuickLinks = [
  {
    display: "Home",
    url: "#",
  },
  {
    display: "Services",
    url: "/servicemain",
  },

  {
    display: "Projects",
    url: "#projects",
  },

  // {
  //   display: "Facilities",
  //   url: "#facilities",
  // },
  {
    display: "Gallery",
    url: "#gallery",
  },
  {
    display: "Contact Us",
    url: "/Contact",
  },
];



const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <section className="tw-pt-0 tw-pb-0">

<div className="first-nav tw-h-[6vh] tw-items-center tw-justify-center tw-gap-48 tw-flex">
          <div className="tw-flex tw-gap-1 tw-items-center">
            <div>
            <i class="fa-solid fa-envelope tw-text-gray-400"></i>
            </div>
            <div >
              <h6 className="tw-text-black tw-font-semibold tw-mb-0 tw-text-xs"><a href="mailto:info@mechtechinfra.com" className="tw-no-underline tw-text-black tw-font-bold">info@mechtechinfra.com</a></h6>
            </div>

            <div className="tw-pl-5">
            <i class="fa-solid fa-location-dot tw-text-gray-400"></i>
            </div>
            <div >
              <h6 className="tw-text-black tw-font-semibold tw-mb-0 tw-text-xs">Flat no 501, DTR Enclave, Sita nagar, Madhurawada, Visakhapatnam -530048</h6>
            </div>
            
          </div>
        
          <div class="wrapper tw-flex tw-items-center tw-gap-5 social-know">
          <ul class="header-top-social-list tw-flex tw-items-center tw-mb-0 tw-gap-2">

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-facebook tw-text-black"></i>
              </a>
            </li>

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-twitter tw-text-black"></i>
              </a>
            </li>

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-instagram tw-text-black"></i>
              </a>
            </li>

          </ul>

          <button class="header-top-btn tw-bg-black tw-p-[6px] tw-text-sm tw-rounded-md hover:tw-bg-gray-400 hover:tw-text-black"><a className="tw-text-white tw-no-underline tw-font-semibold hover:tw-text-black" href="#pricing">Know More</a></button>
        </div>

        </div>

    <header className="header tw-bg-[#f3b100] 2xl:tw-h-[14vh] 3xs:tw-h-[13.9vh] md:tw-h-[16vh] tw-items-center ">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo">
            <h2 className=" d-flex align-items-center gap-1 tw-w-28 tw-mt-4">
              <a href="/"><img src={logo} alt="" /></a>
            </h2>
          </div>

          <div className="nav d-flex align-items-center tw-gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list tw-gap-10">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
                
              </ul>
            </div>

            
          </div>

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
      
      {/*Contact*/}
      
      <div className="servicebg3">
      <Service3/>
      </div>

    {/* Enquiry Form */}
                  
    {/* <Contactenq/> */}

      {/* Footer Starts Here */}

      <section className="tw-bg-[#f3b100] footerbg">
        <Container >
        <Row>
          <Col lg="3" md="6" className="mb-4">
          <h2 className=" d-flex align-items-center gap-1 tw-w-40">
              <img src={logo} alt="" />
            </h2>

            <div className="follows">
              <p className="mb-0 tw-text-white">Follow us on social media</p>
              <span>
                {" "}
                <a href="facebook.com">
                  <i class="ri-facebook-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com">
                  <i class="ri-instagram-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com">
                  <i class="ri-linkedin-line tw-text-white"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="facebook.com">
                  <i class="ri-twitter-line tw-text-white"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="text fw-bold tw-underline tw-text-black tw-font-bold tw-mb-0">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item tw-mb-[-55px]">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          

          <Col lg="3" md="6">
            <h6 className="fw-bold tw-underline">Address</h6>

            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Flat no 501, DTR Enclave, </p>
            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Sita nagar,Madhurawada, </p>
            <p className="tw-font-bold tw-mb-[10px] tw-text-white">Visakhapatnam -530048.</p>
          </Col>
          <Col>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.3847007778427!2d83.35053401268361!3d17.820592077188422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b7341b1a4e7%3A0xe9442a844769b468!2sDTR%20Residency!5e0!3m2!1sen!2sin!4v1708922048953!5m2!1sen!2sin" width="250" height="200" style={{border:"0", borderEndEndRadius:"20px", borderTopLeftRadius:"20px",borderTopRightRadius:"20px",borderBottomLeftRadius:"20px",alignItems:"center",textAlign:"center",}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>
        </Row>
      </Container>
      <div className="tw-text-center tw-text-black  tw-mb-[-70px]">Made with ❤️ by <a className="tw-text-white tw-font-bold tw-no-underline hover:tw-text-black tw-transition tw-ease-in-out" href="https://www.konkorde.org" target="/">KONKORDE</a></div>
      </section>
    </header>
    </section>
  );
};

export default Header;
