import React from "react";
import "./vision.css";
import {Container, Row, Col} from "reactstrap";
import Slider from "react-slick";

import img from "../../assests/images/testimonial01.png";

const Testimonials = () => {
    const settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1
    };
    return (
        <section className="p-0">
            <Container>
                <Row>

                    <div
                        className="testimonial__wrapper d-flex  justify-content-between align-items-center ">

                        <div className="testimonial__content w-100 ">
                            {/* <h3 className="tw-mb-10 tw-text-center fw-bold tw-text-4xl">Our Students Voice</h3> */}

                            <div className=" d-flex md:tw-flex-row tw-flex-col tw-m-auto tw-justify-center">
                                    {/* <div>
                                        <img src={img} alt="" srcset=""/>
                                    </div> */}
                                    

                                    <div className="w-full md:tw-w-[100%] tw-ml-5">
                                    <h3 className="tw-mb-2 tw-text-center fw-bold tw-text-5xl">Our Vision</h3>
                                        <div className="single__testimonial">
                                            {/* <h6 className="mb-3 fw-bold">
                                                Excellent course of materials
                                            </h6> */}
                                            <p className="tw-text-justify tw-mr-5 tw-text-black">
                                            Pioneering the future of construction, Mechtech Infra envisions a world where every structure seamlessly combines innovation, sustainability, and precision. We strive to be the industry benchmark, known for transforming spaces with cutting-edge technology, superior craftsmanship, and a relentless commitment to client satisfaction.
                                            As a trailblazer in construction, Mechtech Infra aspires to create enduring legacies with structures that stand the test of time. Our vision extends to shaping urban environments where sustainability, efficiency, and aesthetic brilliance converge, leaving an indelible mark on the world.
                                            </p>

                                        </div>
                                    </div>
                                </div>

                                <div className=" d-flex md:tw-flex-row tw-flex-col tw-m-auto tw-justify-center">
                                    {/* <div>
                                        <img src={img} alt="" srcset=""/>
                                    </div> */}
                                    

                                    <div className="w-full md:tw-w-[100%] tw-ml-5">
                                    <h3 className="tw-mb-2 tw-text-center fw-bold tw-text-5xl">Our Mission</h3>
                                        <div className="single__testimonial">
                                            {/* <h6 className="mb-3 fw-bold">
                                                Excellent course of materials
                                            </h6> */}
                                            <p className="tw-text-justify tw-mr-5 tw-text-black">
                                            &#x2022;To redefine construction through innovative solutions, advanced technology, and sustainable practices. Mechtech Infra is committed to delivering excellence, ensuring timely project completion, and prioritizing the well-being and growth of our workforce.</p>
                                            <p className="tw-text-justify tw-mr-5 tw-text-black">
                                            &#x2022;Mechtech Infra is dedicated to fostering client trust, embracing continuous improvement, and creating an empowering workplace that nurtures talent and innovation.  
                                            </p>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </div>

                </Row>
            </Container>
        </section>
    );
};

export default Testimonials;
