import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import chooseImg from "../../assests/images/why-choose-us.png";
import "./choose-us.css";

import ReactPlayer from "react-player";
import first from '../../assests/Icons/first.png'
import second from '../../assests/Icons/second.png'
import third from '../../assests/Icons/third.png'
import fourth from '../../assests/Icons/fourth.png'
import tech from '../../assests/Icons/tech.png'
import eco from '../../assests/Icons/eco.png'
import time from '../../assests/Icons/time.png'
import labour from '../../assests/Icons/labour.png'

const ChooseUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section className="tw-bg-[#DFE4E6] servicebg2" id="facilities">
      <div class="tw-flex tw-items-center tw-py-4">
        
        <div class="tw-flex-grow tw-h-px tw-bg-black"></div> 

        
        <span class="tw-flex-shrink tw-text-xl tw-text-black tw-px-4 tw-italic tw-font-bold">The MechTech Infra Advantage</span>

        
        <div class="tw-flex-grow tw-h-px tw-bg-black"></div>
    </div>
    <div className="tw-text-center">
      <h1 className="tw-font-bold">Innovating Today for a Sustainable Tomorrow...</h1>
    </div>
    <div className="tw-flex tw-m-24 3xs:tw-flex-col xl:tw-flex-row" id="features">
    <div className="tw-flex tw-items-center tw-gap-5" id="features-down">
      <div>
        <img src={tech} alt="" className="tw-w-28 tw-rounded-full" />
      </div>
      <div className="tw-w-[70%] 3xs:tw-text-justify lg:tw-text-left">
        <h4 className="sm:tw-font-bold tw-text-center sm:tw-text-left tw-font-semibold">Futuristic Technology</h4>
        <p className="tw-text-black tw-text-justify">Embrace the future with Mechtech Infra's cutting-edge construction technologies, integrating robotics and AI for precision and efficiency, ensuring a sustainable and seamless building experience.</p>
      </div>
    </div>
    <div className="tw-flex tw-items-center tw-gap-5 3xs:tw-mb-[-60px] xl:tw-mb-0" id="features-down">
      <div>
        <img src={eco} alt="" className="tw-w-28 tw-rounded-full"/>
      </div>
      <div className="tw-w-[70%] 3xs:tw-text-justify lg:tw-text-left">
        <h4 className="sm:tw-font-bold tw-text-center sm:tw-text-left tw-font-semibold">Eco Friendly</h4>
        <p className="tw-text-black tw-text-justify">Step into an eco-friendly future with Mechtech Infra, where sustainable construction practices blend seamlessly with innovation, offering environmentally conscious solutions for a greener tomorrow.</p>
      </div>
    </div>
    </div>
    <div className="tw-flex tw-m-24 3xs:tw-flex-col xl:tw-flex-row xl:tw-gap-40" id="features">
    <div className="tw-flex tw-items-center tw-gap-5 3xs:tw-mt-20 md:tw-mt-0" id="features-down">
      <div>
        <img src={time} alt="" className="tw-w-28 tw-rounded-full" />
      </div>
      <div className="tw-w-[70%] 3xs:tw-text-justify lg:tw-text-left">
        <h4 className="sm:tw-font-bold tw-text-center sm:tw-text-left tw-font-semibold">On-time fulfillment</h4>
        <p className="tw-text-black">Experience a future of In-Time Submission with Mechtech Infra, where innovative construction practices ensure timely project delivery.</p>
      </div>
    </div>
    <div className="tw-flex tw-items-center tw-gap-5 " id="features-down">
      <div>
        <img src={labour} alt="" className="tw-w-28 tw-rounded-full"/>
      </div>
      <div className="tw-w-[70%] 3xs:tw-text-justify lg:tw-text-left">
        <h4 className="sm:tw-font-bold tw-text-center sm:tw-text-left tw-font-semibold">Exceptional manpower</h4>
        <p className="tw-text-black tw-text-justify">At Mechtech Infra, we pride ourselves on exemplary craftsmanship, as our dedicated labor force consistently delivers excellence, upholding the highest standards in construction.</p>
      </div>
    </div>
    </div>
    </section>
  );
};

export default ChooseUs;
