import React from "react";
import {Container, Row, Col} from "reactstrap";
import gal1 from "../../assests/gallery/gal1.jpg";
import gal2 from "../../assests/gallery/gal2.jpg";
import gal3 from "../../assests/gallery/gal3.jpg";
import gal4 from "../../assests/gallery/gal4.jpg";
import gal5 from "../../assests/gallery/gal5.jpg";
import gal6 from "../../assests/gallery/gal6.jpg";
import gal7 from "../../assests/gallery/gal7.jpg";
import gal8 from "../../assests/gallery/gallery.jpg";
import gallery from '../../assests/gallery/gallery.jpg';

const Gallery = () =>{
    

    return(
        

// ImageGallery.jsx


<section className="tw-bg-[#DFE4E6] servicebg2" id="gallery">
  <div>
    <h1 className="tw-text-center tw-text-4xl tw-font-bold">Explore Mechtech Infra's Excellence Gallery.</h1>
    </div>
<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 lg:tw-m-20 3xs:tw-m-10">
 
 <img
   src={gal1}
   alt="Image 1"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal2}
   alt="Image 2"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal3}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal4}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal5}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal6}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal7}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 <img
   src={gal8}
   alt="Image 3"
   className="tw-rounded-2xl tw-object-cover tw-w-full tw-h-48 md:tw-h-64 lg:tw-h-80 hover:tw-shadow-lg"
 />
 </div>
</section>


    );
};

export default Gallery;