import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-img1.png";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <section className="tw-pt-16 tw-bg-[#dfe4e6] servicebg1">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-3 3xs:tw-mt-[-65px] lg:tw-mt-0 hero__title tw-text-6xl tw-font-bold">
              BUILDING <br/> DREAMS ONE PANEL AT A TIME
                
              </h2>
              <p className="mb-5">
              Transforming spaces seamlessly with precision-crafted cement panels. Building homes that redefine comfort and style, one panel at a time.
              </p>
            </div>
            <div className="search">
              
              <button className="btn tw-mb-14"><a href="#facilities" className="tw-no-underline tw-text-white ">Know More</a> <i class="fa-solid fa-circle-down tw-text-white tw-ml-2"></i></button>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
