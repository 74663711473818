import React from "react";
import { Container, Row, Col } from "reactstrap";

const Company = () => {
  return (
    <section className="tw-text-center tw-flex tw-justify-center">
      <Container>
        <Row>
          <Col >
            <h3 className=" d-flex align-items-center gap-1 xl:tw-text-xl 3xs:tw-text-base">
            <i class="fa-solid fa-lightbulb"></i> Innovation
            </h3>
          </Col>

          <Col >
            <h3 className=" d-flex align-items-center gap-1 xl:tw-text-xl 3xs:tw-text-base">
            <i class="fa-solid fa-check"></i> Sustainability
            </h3>
          </Col>          

          <Col >
            <h3 className=" d-flex align-items-center gap-1 xl:tw-text-xl 3xs:tw-text-base">
              {" "}
              <i class="fa-brands fa-codepen"></i> Precision
            </h3>
          </Col>

          <Col >
            <h3 className=" d-flex align-items-center gap-1 xl:tw-text-xl 3xs:tw-text-base">
              {" "}
              <i class="fa-solid fa-star"></i> Quality
            </h3>
          </Col>

          <Col>
            <h3 className=" d-flex align-items-center gap-1 xl:tw-text-xl 3xs:tw-text-base">
              {" "}
              <i class="fa-solid fa-chart-simple"></i> Efficiency
            </h3>
          </Col>
          
          
        </Row>
      </Container>
    </section>
  );
};

export default Company;
