
import Home from "./pages/Home";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "../src/pages/Contact";
import Servicemain from "../src/pages/Servicesmain.jsx";
import Service1 from "../src/pages/Services1.jsx";
import Service2 from "../src/pages/Services2.jsx";
import Service3 from "../src/pages/Services3.jsx";
import About from "../src/pages/Aboutus.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Servicemain" element={<Servicemain />} />
        <Route path="/Service1" element={<Service1 />} />
        <Route path="/Service2" element={<Service2 />} />
        <Route path="/Service3" element={<Service3 />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
