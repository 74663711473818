import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./services.css";

import floor from '../../assests/images/floor.jpg'
import fab from '../../assests/images/fab.jpg'
import rapid from '../../assests/images/rapid.jpg'
import cutting from '../../assests/images/cutting.jpg'
import client from '../../assests/images/client.jpg'
import med3 from '../../assests/images/med3.png'
import warehouses from '../../assests/images/warehouses.jpg' 
import s2_1 from '../../assests/images/s2.1.jpg'
import s2_2 from '../../assests/images/s2.2.jpg'
import s2_3 from '../../assests/images/s2.3.jpg'
import s2_4 from '../../assests/images/s2.4.png'
import s2_5 from '../../assests/images/s2.5.png'
import s2_6 from '../../assests/images/s2_6.png'



const coursesData = [
  {
    id: "01",
    title: "Warehouses",
    lesson: "Mechtech Infra revolutionizes warehousing with innovative design and sustainable solutions, crafting spaces that seamlessly merge efficiency and quality for the future of storage.",
    students: 12.5,
    rating: 5.9,
    imgUrl: warehouses,
  },

  {
    id: "02",
    title: "Mezzanine Floor",
    lesson: "Discover elevated space solutions with Mechtech Infra's mezzanine floors, seamlessly blending innovation and practicality for efficient spatial optimization.",
    students: 12.5,
    rating: 5.9,
    imgUrl: floor,
  },

  {
    id: "03",
    title: "Pre - Fab Structures",
    lesson: "Explore elevated space solutions with Mechtech Infra's pre-fab structures, seamlessly blending innovation and practicality for efficient spatial optimization.",
    students: 12.5,
    rating: 5.9,
    imgUrl: fab,
  },
];

const coursesData1 = [

  {
    id: "02",
    title: "Cutting-Edge Technology Integration",
    lesson: "Utilizing state-of-the-art technology for precision and efficiency in construction processes.",
    students: 12.5,
    rating: 5.9,
    imgUrl:  cutting,
  },

  {
    id: "01",
    title: "Internal Partitions / Rapid Construction",
    lesson: "Rapidly redefine interiors with Mechtech Infra's swift construction of internal partitions, seamlessly blending speed and quality for efficient and stylish transformations.",
    students: 12.5,
    rating: 5.9,
    imgUrl: rapid,
  },

  {
    id: "03",
    title: "Client-Centric Project Customization",
    lesson: "Tailoring solutions to client needs, ensuring transparent communication and surpassing expectations in every project.",
    students: 12.5,
    rating: 5.9,
    imgUrl: client,
  },
  
];

const Courses = () => {
  return (
    <section id="courses">
      <Container>
      
        <h1 className="tw-text-center tw-font-bold tw-border-b-2 tw-border-black tw-mb-16">Tailored Construction Solutions: Precise Precasted Boundary Walls & Metal Beam Crash Barriers</h1>
            <div className="lg:tw-flex tw-gap-[10%] tw-items-center">
              <div class="wrapper2">
                    
                    <div class="container2">
                    <div class="horizontal"><img src={s2_1} alt="" /></div>
                    <div class="vertical"><img  src={s2_2} alt="" /></div>
                    <div><img src={s2_6} alt="" /></div>
                    <div class="big"><img src={s2_4} alt="" /></div>
                    <div class="vertical1"><img className="img" src={s2_5} alt="" /></div>                    
                    <div><img src={s2_3} alt="" /></div>
                </div>
            </div>
            <div className="text">
              <p className="tw-max-w-6xl tw-text-justify tw-text-black">Mechtech Infra emerges as the epitome of trust and excellence in the construction realm, renowned for delivering meticulous and tailored construction solutions. Our repertoire includes the provision of customized Precasted Boundary Walls, intricately designed to meet precise client specifications, alongside our proficient installation services for metal beam crash barriers, which assure enhanced safety and security on every project. With Mechtech Infra by your side, rest assured of unparalleled precision and reliability in every aspect of your construction endeavors.</p>
              <div className="tw-flex tw-gap-5">
                <div className="items">
                  <ul className="tw-list-disc tw-font-bold tw-leading-10">
                    <li>Precasted Boundary walls</li>
                    <li>Metal Beam Crash Barrier</li>
                    <li>Kerb Casting </li>
                  </ul>
                </div>
                
              </div>
            </div>
            </div>



      </Container>
    </section>
  );
};

export default Courses;
