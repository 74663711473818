import React, { useRef } from "react";
import { Container } from "reactstrap";
import "./header.css";

import {Link} from "react-router-dom"
import logo from '../../assests/Logos/logo-main.png'

const navLinks = [
  {
    display: "Home",
    url: "https://www.mechtechinfra.com/",
  },
  {
    display: "About",
    url: "/about",
  },

  {
    display: "Services",
    url: "/servicemain",
  },
  {
    display: "Projects",
    url: "#projects",
  },
  // {
  //   display: "Facilities",
  //   url: "#facilities",
  // },
  {
    display: "Gallery",
    url: "#gallery",
  },
  {
    display: "Contact Us",
    url: "/contact",
  },
];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    
    <section className="tw-pt-0 tw-pb-0">
      <div className="first-nav tw-h-[6vh] tw-items-center tw-justify-center tw-gap-44 tw-flex">
          <div className="tw-flex tw-gap-1 tw-items-center">
            <div>
            <i class="fa-solid fa-envelope tw-text-gray-400"></i>
            </div>
            <div >
              <h6 className="tw-text-black tw-font-semibold tw-mb-0 tw-text-xs"><a href="mailto:info@mechtechinfra.com" className="tw-no-underline tw-text-black tw-font-bold">info@mechtechinfra.com</a></h6>
            </div>

            <div className="tw-pl-5">
            <i class="fa-solid fa-location-dot tw-text-gray-400"></i>
            </div>
            <div >
              <h6 className="tw-text-black tw-font-semibold tw-mb-0 tw-text-xs">Flat no 501, DTR Enclave, Sita nagar, Madhurawada, Visakhapatnam -530048</h6>
            </div>
            
          </div>
        
          <div class="wrapper tw-flex tw-items-center tw-gap-5 social-know">
          <ul class="header-top-social-list tw-flex tw-items-center tw-mb-0 tw-gap-2">

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-facebook tw-text-black"></i>
              </a>
            </li>

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-twitter tw-text-black"></i>
              </a>
            </li>

            <li>
              <a href="#" class="header-top-social-link">
                <i class="fa-brands fa-instagram tw-text-black"></i>
              </a>
            </li>

          </ul>

          <button class="header-top-btn tw-bg-black tw-p-[6px] tw-text-sm tw-rounded-md hover:tw-bg-gray-400 hover:tw-text-black"><a className="tw-text-white tw-no-underline tw-font-semibold hover:tw-text-black" href="#pricing">Know More</a></button>
        </div>

        </div>
      <header className="header tw-bg-[#f3b100] 2xl:tw-h-[14vh] 3xs:tw-h-[13.9vh] md:tw-h-[16vh] tw-items-center ">
      
      <Container>
        
        <div className="navigation d-flex align-items-center justify-content-between" id="main">
          <div className="logo">
            <h2 className=" d-flex align-items-center gap-1 tw-w-28 tw-m-4">
              <a href="/"><img src={logo} alt="" /></a>
            </h2>
          </div>

          <div className="nav d-flex align-items-center tw-gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list lg:tw-gap-10">
                {navLinks.map((item, index) => (
                  <li  key={index} className="nav__item ">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
                
              </ul>
            </div>

            
          </div>

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
    </section>
  );
};

export default Header;
