import React from "react";
import { Container, Row, Col } from "reactstrap";
import FreeCourseCard from "./FreeCourseCard";
import Science from "../../assests/Clubs/Science.png"
import Art from "../../assests/Clubs/Art.png"
import Lang from "../../assests/Clubs/Lang.png"
import Sports from "../../assests/Clubs/Sports.png"
import "./free-course.css";
import gallery from '../../assests/gallery/gallery.jpg'

const freeCourseData = [
  {
    id: "01",
    title: "Project 1",
    imgUrl: gallery,
    students: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    
  },
  {
    id: "02",
    title: "Project 2",
    imgUrl: gallery,
    students: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    
  },

  {
    id: "03",
    title: "Project 3",
    imgUrl: gallery,
    students: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    
  },

  {
    id: "04",
    title: "Project 4",
    imgUrl: gallery,
    students: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    Text:"and many more....."
  },
];

const FreeCourse = () => {
  return (
    <section className="tw-pt-3" id="projects">
      <Container>
        <Row>
          <Col lg="12" className="text-center mb-5">
            <h2 className="fw-bold tw-text-5xl">Our Projects</h2>
          </Col>

          {freeCourseData.map((item) => (
            <Col lg="3" md="4" className="mb-4" key={item.id}>
              <FreeCourseCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
      
    </section>
  );
};

export default FreeCourse;
