import React, { Fragment } from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/Hero-Section/HeroSection";
import CompanySection from "../components/Company-section/Company";

// import AboutUs from "../components/About-us/AboutUs";

import ChooseUs from "../components/Choose-us/ChooseUs";
import Features from "../components/Feature-section/Features";
import FreeCourse from "../components/Free-course-section/FreeCourse";
import Gallery from "../components/Gallery/gallery";


import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <HeroSection />
      <CompanySection />
      {/* <AboutUs /> */}
      
      <ChooseUs />
      <Features />
      <FreeCourse />
      
  
      <Gallery/>
      <Footer />
    </Fragment>
  );
};

export default Home;